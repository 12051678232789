<template>
  <fm-form-dialog
    form-title="请假条"
    :open-dialog.sync="modal"
    :form-parms="formParms"
    :old-data="formData"
    :mask-closable="false"
    label-alone
    label-align="left"
    form-width="800px"
    @formSubmit="formSubmit"
    @handleClose="modal = false">
  </fm-form-dialog>
</template>

<script>
import {
  askLeaveApplyRequest
} from '../../../api'

import {
  dateOperating
} from '@/fmlib'

export default {
  props: {
    data: { type: Object, defualt: () => null }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          total: ''
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {},
      modal: false
    }
  },
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'select',
          label: '请假类型',
          selectDatas: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {return {key: v, label: v}}),
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'datetimePicker',
          label: '开始时间',
          key: 'startTime',
          check: {
            required: true
          }
        },
        {
          type: 'datetimePicker',
          label: '结束时间',
          key: 'endTime',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '请假时长',
          key: 'timeLong',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '请假事由',
          key: 'reson',
          check: {
            required: true
          }
        }]
        return data
      }
    }
  },
  methods: {
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    async formSubmit (data, resolve) {
      if (data.startTime && data.endTime && this.timeBj(data.startTime, data.endTime)) {
        this.$notice.info({
          title: '系统提示',
          desc: '结束时间需要大于开始时间。'
        })
        resolve()
        return
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
      }
      if (data.id) {
        await askLeaveApplyRequest.update(data.id, data)
      } else {
        await askLeaveApplyRequest.add(data)
      }
      resolve()
      this.$emit('dataUpdate', 'add')
      this.modal = false
    }
  }
}
</script>
