<template>
  <fm-form-dialog
    form-title="请假申请分配"
    :open-dialog.sync="modal"
    :form-parms="formParms"
    :old-data="formData"
    :mask-closable="false"
    label-alone
    :on-change="onFormChange"
    label-align="left"
    form-width="800px"
    @formSubmit="formSubmit"
    @handleClose="modal = false">
  </fm-form-dialog>
</template>

<script>
import {
  askLeaveApplyRequest
} from '../../../api'

export default {
  props: {
    data: { type: Object, defualt: () => null },
    applyTypeList: { type: Array, defualt: () => [] },
    config: { type: Object, defualt: () => {} }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.submitSwitch = this.data && this.data.applyType ? this.config[Number(this.data.applyType)].find(v => v.updateKeys) : null
        let updateKeys = []
        if (this.submitSwitch) {
          updateKeys = this.submitSwitch.updateKeys.split(',')
        }
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {}
        this.formData.updateKeys = updateKeys
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {},
      submitSwitch: null,
      modal: false
    }
  },
  computed: {
    workerUserList () {
      return this.$store.getters.workerUserList
    },
    formParms: {
      get () {
        let data = [{
          type: 'select',
          label: '审批类型',
          selectDatas: this.applyTypeList,
          key: 'applyType',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '科主任',
          selectDatas: this.workerUserList,
          key: 'orgLeadUserId',
          check: {
            required: true
          },
          show (data) {
            return data.updateKeys && data.updateKeys.includes('orgLeadUserId')
          }
        },
        {
          type: 'select',
          label: '部门分管领导',
          selectDatas: this.workerUserList,
          key: 'fgLeadUserId',
          check: {
            required: true
          },
          show (data) {
            return data.updateKeys && data.updateKeys.includes('fgLeadUserId')
          }
        },
        {
          type: 'select',
          label: '人事部门领导',
          selectDatas: this.workerUserList,
          key: 'personnelUserId',
          check: {
            required: true
          },
          show (data) {
            return data.updateKeys && data.updateKeys.includes('personnelUserId')
          }
        },
        {
          type: 'select',
          label: '副院长',
          selectDatas: this.workerUserList,
          key: 'associateDeanUserId',
          check: {
            required: true
          },
          show (data) {
            return data.updateKeys && data.updateKeys.includes('associateDeanUserId')
          }
        },
        {
          type: 'select',
          label: '院长',
          selectDatas: this.workerUserList,
          key: 'deanUserId',
          check: {
            required: true
          },
          show (data) {
            return data.updateKeys && data.updateKeys.includes('deanUserId')
          }
        }]
        return data
      }
    }
  },
  methods: {
    onFormChange (key, value) {
      if (key === 'applyType') {
        if (value) {
          let updateKeys = []
          this.submitSwitch = null
          if (this.config[value] && this.config[value].find(v => v.updateKeys)) {
            this.submitSwitch = this.config[value].find(v => v.updateKeys)
            updateKeys = this.submitSwitch.updateKeys.split(',')
          }
          return {
            updateKeys,
            orgLeadUserId: null,
            fgLeadUserId: null,
            personnelUserId: null,
            associateDeanUserId: null,
            deanUserId: null
          }
        }
        this.submitSwitch = null
        return {
          updateKeys: [],
          orgLeadUserId: null,
          fgLeadUserId: null,
          personnelUserId: null,
          associateDeanUserId: null,
          deanUserId: null
        }
      }
    },
    async formSubmit (data, resolve) {
      if (!this.submitSwitch) {
        this.$notify({
          title: '系统提示',
          message: '请假流程配置异常,请联系管理员!',
          type: 'info'
        })
        resolve()
        return
      }
      if (data.batchIdList && data.batchIdList.length > 0) {
        let pl = data.batchIdList.map(v => {
          askLeaveApplyRequest.assign(v, {
            applyType: data.applyType
          })
        })
        await Promise.all(pl)
        let parm = {
          statusActionKey: this.submitSwitch.statusActionKey
        }
        let pl2 = data.batchIdList.map(v => {
          askLeaveApplyRequest.switchStatus(v, Object.assign({
            orgLeadUserId: data.orgLeadUserId,
            fgLeadUserId: data.fgLeadUserId,
            personnelUserId: data.personnelUserId,
            associateDeanUserId: data.associateDeanUserId,
            deanUserId: data.deanUserId
          }, parm))
        })
        await Promise.all(pl2)
      } else if (data.id) {
        await askLeaveApplyRequest.assign(data.id, {
          applyType: data.applyType
        })
        let parm = {
          statusActionKey: this.submitSwitch.statusActionKey
        }
        await askLeaveApplyRequest.switchStatus(data.id, Object.assign({
          orgLeadUserId: data.orgLeadUserId,
          fgLeadUserId: data.fgLeadUserId,
          personnelUserId: data.personnelUserId,
          associateDeanUserId: data.associateDeanUserId,
          deanUserId: data.deanUserId
        }, parm))
      }
      resolve()
      this.$emit('dataUpdate', 'assign')
      this.modal = false
    }
  }
}
</script>
